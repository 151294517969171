import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import * as Constants from '../Constants';
import './Anime.css';
import useWindowSize from "../hooks/useWindowSize";

function Anime(props) {
  const [openSecondaryView, setopenSecondaryView] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false); /* se refiere a la carga inicial del elemento */
  const [updateMessage, setUpdateMessage] = useState('');
  const [updateMessageType, setUpdateMessageType] = useState('warning');
  const [scoreInputValue, setScoreInputValue] = useState('');
  const windowSize = useWindowSize();

  let hoverTimeout = null;

  function parseTimeUntilNextEp(timeUntilNextEpisode) {
    if(!timeUntilNextEpisode)
      return '';
    else if(timeUntilNextEpisode < 0)
      return '0d 0h 0m';
    else {
      const days = Math.floor(timeUntilNextEpisode / (24 * 3600));
      const hours = Math.floor(timeUntilNextEpisode % (24 * 3600) / 3600);
      const minutes = Math.floor(timeUntilNextEpisode % 3600 / 60);

      return `${days}d ${hours}h ${minutes}m`;
    }
  }

  function mouseEnterHandler(event) {
    if(!finishedLoading)
      setFinishedLoading(true);

    setopenSecondaryView(true);
    if(hoverTimeout)
      clearTimeout(hoverTimeout);
  }

  function mouseLeaveHandler({timeoutDuration}) {
    if(!timeoutDuration)
      timeoutDuration = 700;

    hoverTimeout = setTimeout(() => {
      setopenSecondaryView(false);
      //console.log("close secondary view");
    }, timeoutDuration);
  }

  function getCSSClasses() {
    if(!finishedLoading)
      return 'anime-secondary';
    else if(openSecondaryView)
      return 'anime-secondary opened';
    else 
      return 'anime-secondary closed';
  }

  async function updateWatchedEpisodes() {
    if(props.episodesWatched < props.episodesReleased) {
      try {
        const userToken = localStorage.getItem('userToken');
        if(!userToken)
          throw new Error();

        setUpdateMessageType('warning');
        setUpdateMessage("Loading...");

        await axios.patch(Constants.UPDATE_WATCHED_EPISODES_URL, {
          malId: props.id,
          newEpisodesWatched: props.episodesWatched + 1
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        });
        
        props.updateWatchedEpisodes(props.id, props.episodesWatched + 1);

        setUpdateMessageType('success');
        setUpdateMessage(`Updated the number of episodes watched from ${props.episodesWatched} to ${props.episodesWatched + 1}`);
      } catch(err) {
        setUpdateMessageType('error');
        setUpdateMessage("Couldn't update the episode count, please try again later.");
      }
    }
  }

  async function dropAnime() {
    try {
      const userToken = localStorage.getItem('userToken');
      if(!userToken)
        throw new Error();

      setUpdateMessageType('warning');
      setUpdateMessage("Loading...");

      await axios.patch(Constants.DROP_ANIME_URL, {
        malId: props.id,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });

      setUpdateMessageType('success');
      setUpdateMessage('Updated the status to dropped, it will now get removed from your schedule shortly.');

      setTimeout(() => {
        props.dropAnime(props.id);
      }, 5000);
    } catch(err) {
      setUpdateMessageType('error');
      setUpdateMessage("Couldn't update the status to dropped, please try again later.");
    }
  }

  async function updateScore() {
    try {
      if(!scoreInputValue || ( scoreInputValue < 0 || scoreInputValue > 10))
        throw new Error('invalid score value');

      const userToken = localStorage.getItem('userToken');
      if(!userToken)
        throw new Error('user not found');

      setUpdateMessageType('warning');
      setUpdateMessage('Loading...');

      await axios.patch(Constants.UPDATE_ANIME_SCORE_URL, {
        malId: props.id,
        score: scoreInputValue
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      
      props.updateScore(props.id, scoreInputValue);

      setUpdateMessageType('success');
      setUpdateMessage(`Updated the score to ${scoreInputValue}`);

    } catch(err) {
      console.log(err.message);
      setUpdateMessageType('error');
      if(err.message === 'user not found')
        setUpdateMessage('Session expired, please login and try again later.');
      else if(err.message === 'invalid score value')
        setUpdateMessage('Invalid score value.');
      else
        setUpdateMessage('Couldn\'t update the score, please try again later.');
    }
  }

  useEffect(() => {
    if(openSecondaryView &&  window.innerWidth < 960) {
      mouseLeaveHandler({timeoutDuration: 4000});
    }
  }, [openSecondaryView, scoreInputValue]);

  return (
    <>
    {(props.upToDateToggle === true || (props.episodesWatched < props.episodesReleased)) &&
      <div className={(props.episodesWatched === props.episodesReleased) ? 'anime-container uptodate' : 'anime-container'} 
        onMouseEnter={(windowSize.width > 960) ? mouseEnterHandler : undefined } onMouseLeave={mouseLeaveHandler} onClick={mouseEnterHandler}
      >
        <div className='anime'>
          <div className='anime-img' style={{backgroundImage: `url(${props.picture})`}}/>
          <div className='anime-info'>
            <p className='anime-prop anime-title'>{props.title}</p>
            <p className='anime-prop'>Episodes watched: {props.episodesWatched}</p>
            <p className='anime-prop'>Episodes released: {props.episodesReleased}</p>
            <p className='anime-prop'>
              {(props.timeUntilNextEpisode) ? 
              `Next episode in: ${parseTimeUntilNextEp(props.timeUntilNextEpisode - props.minutesPassed * 60)}` 
              : <br/>}
            </p>
          </div>
        </div>
        <div className={getCSSClasses()}>
          <a className='anime-btn visit-btn' target='_blank' href={`https://myanimelist.net/anime/${props.id}/`} >Visit MyAnimeList</a>
          <button className='anime-btn watched-btn' onClick={updateWatchedEpisodes}>Watched</button>
          <button className='anime-btn drop-btn' onClick={dropAnime}>Drop</button>
          <input className='score-input' placeholder={`Score (${props.score})`} type='number' 
            min='0' max='10' value={scoreInputValue} onChange={(e) => setScoreInputValue(e.target.value)}/>
          <button className='anime-btn score-btn' onClick={updateScore}>Set score</button>
          <p className={`update-message ${updateMessageType}`}>{updateMessage}</p>
        </div>
      </div>
      
    }
    </>
  );
}

export default Anime;
