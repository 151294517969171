export const MY_ANIME_LIST_API_URL = 'https://myanimelist.net/v1/oauth2/authorize';
export const CLIENT_ID = '49d9bd99863d19018e24bf55ff482264';

let SERVER_URL = '';
let SCHEDULE_WEB_URL = '';
if(process.env.NODE_ENV == 'production') {
  SCHEDULE_WEB_URL = 'https://web-9schedule.pages.dev/';
  SERVER_URL = 'https://nineschedule.onrender.com/';
  // SERVER_URL = 'https://server-9schedule.herokuapp.com/';
}
else {
  SCHEDULE_WEB_URL = 'http://localhost:3000/';
  SERVER_URL = 'http://localhost:3001/';
}

export { SCHEDULE_WEB_URL };
export const SERVER_LOGIN_URL = SERVER_URL + 'auth/login';
export const FETCH_USER_ANIME_LIST_URL = SERVER_URL + 'user/animeList';
export const FETCH_ANIME_AIRING_TIMES_URL = SERVER_URL + 'user/animeList/airingTimes';
export const UPDATE_WATCHED_EPISODES_URL = SERVER_URL + 'user/animeList/updateWatchedEpisodes';
export const DROP_ANIME_URL = SERVER_URL + 'user/animeList/dropAnime';
export const UPDATE_ANIME_SCORE_URL = SERVER_URL + 'user/animeList/updateAnimeScore';

export const AIRING_STATUS = 'currently_airing';
export const NOT_AIRED_YET_STATUS = 'not_yet_aired';